import { createAsyncThunk } from "@reduxjs/toolkit";
import * as aTypes from "./types";
import insertProcessAPI from "../../api/insertProcessAPI";
import { setupErrorPayload } from "../../app/config/utils";

export const triggerInsertProcess = createAsyncThunk(
  aTypes.TRIGGER_INSERT_PROCESS,
  async (adClientId = "", thunkAPI) => {
    try {
      const res = await insertProcessAPI.triggerInsertProcess(
        thunkAPI.getState().auth.jwt,
        adClientId
      );
      console.log("TRIGGER_INSERT () => ", res);
      return res;
    } catch (error) {
      console.log("TRIGGER_INSERT_ERROR () => ", error);
      const payload = setupErrorPayload(error);
      return thunkAPI.rejectWithValue(payload);
    }
  }
);
