import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";
import routes from "./routes";

import { useSelector } from "react-redux";

const PrivateOutlet = () => {
  const isAuthenticated = useSelector((state) => state.auth.isSuccess);
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

const App = () => {
  const { login, insertProcess } = routes;
  return (
    <Router>
      <Routes>
        <Route path={login.path} element={<login.component />} />

        <Route path="*" element={<login.component />} />
        {/* PRIVATE ROUTES */}
        <Route path="/" element={<PrivateOutlet />}>
          <Route path={insertProcess.path} element={<insertProcess.component />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
