import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../services/auth/authSlice";
import insertProcessReducer from "../services/insertProcess/insertProcessSlice";
import { saveState, loadState } from "./localStorage";
import logger from "redux-logger";
import { rtkQueryForbidden } from "./middlewares";

const persistedState = loadState();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    insertProcess: insertProcessReducer,
  },
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger, rtkQueryForbidden),
});

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
    insertProcess: store.getState().insertProcess,
  });
});
