import React, { useState } from "react";
import PropTypes from "prop-types";
import LoginView from "./LoginView";
import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { login, getUser, getRole, saveRut } from "../../../services/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { isBlumosInsertion, isSolutecInsertion } from "../../../services/auth/selectors";

const validationSchema = yup.object({
  email: yup.string("Ingresa tu correo").required("Debes ingresar tu correo"),
  password: yup
    .string("Ingresa la contraseña")
    .min(4, "La contraseña debe tener al menos 4 caracteres.")
    .required("Debes ingresar tu contraseña."),
});

const Login = (props) => {
  const dispatch = useDispatch();
  const [roleError, setRoleError] = useState(false);
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = useSelector((state) => state.auth.isSuccess);
  const isError = useSelector((state) => state.auth.isError);
  const loading = useSelector((state) => state.auth.isLoading);
  const isSuccessUser = useSelector((state) => state.auth.isSuccessUser);
  const isSolutecInsetable = useSelector((state) => isSolutecInsertion(state));
  const isBlumosInsertable = useSelector((state) => isBlumosInsertion(state));
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (credentials) => {
      dispatch(() => dispatch(saveRut(formik.values.email)));
      dispatch(() => dispatch(login(credentials)));
    },
  });

  React.useEffect(() => {
    if (isAuthenticated) {
      if (!isBlumosInsertable && !isSolutecInsetable) {
        setRoleError(true);
      }
    }
    if (isAuthenticated && isSuccessUser) {
      // Load init data
      // redirect
      setRoleError(false);
      navigate("/insertProcess");
    }
  }, [isAuthenticated, isSuccessUser]);

  React.useEffect(() => {
    if (isError) formik.setFieldError("password", "Credenciales incorrectas");
  }, [isError]);

  return (
    <div>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "primary.main",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-8px",
            marginLeft: "-12px",
          }}
        />
      )}
      <LoginView
        roleError={roleError}
        formik={formik}
        isError={isError}
        loading={loading}
        setRoleError={setRoleError}
      />
    </div>
  );
};

Login.propTypes = {};

export default Login;
