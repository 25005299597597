import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import logo from "app/assets/svg/logo.svg";

const NavbarView = ({ handleLogout, initials, rut }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleInternalLogout = () => {
    setAnchorElUser(null);
    handleLogout();
  };
  return (
    <AppBar color={"transparent"} elevation={0} position={"static"}>
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters sx={{ pb: 1, pt: 2 }}>
          <Typography variant={"h6"} noWrap component={"div"} sx={{ width: 180 }}>
            <img style={{ width: 160, maxWidth: "100%" }} src={logo} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              width: 180,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                <Avatar alt="Remy Sharp">{initials}</Avatar>
              </IconButton>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleInternalLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

NavbarView.propTypes = {};

export default NavbarView;
