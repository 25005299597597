import Login from "../pages/login/Login";
import InsertProcess from "../pages/insertProcess/InsertProcess";

const routes = {
  login: {
    path: "/",
    component: Login,
  },
  insertProcess: {
    path: "/insertProcess",
    component: InsertProcess,
  },
};

export default routes;
