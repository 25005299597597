export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5800";
export const API_URL = `${SERVER_URL}/api/v1/tdinventario`;
export const AUTH_SERVER = `${SERVER_URL}/api`;

export const APPLICATION_BASE_URL = "/";

// AJAX
export const AJAX_REQUEST_TIMEOUT = 15 * 1000;

window.LOG_LEVEL = process.env.NODE_ENV !== "production" ? 0 : 3;
// window.LOG_LEVEL =3;
window.DEBUG_SLOW_PAGING = false;
