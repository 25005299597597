import * as URLS from "./utils/urls";
import request from "./utils/request";

const fetchLogin = async (credentials) => {
  const resp = await request(URLS.AUTH_LOGIN, "GET", {}, null, {
    username: credentials.email,
    password: credentials.password,
  });
  if (resp.data) {
    console.log("RES", resp.data);
    localStorage.setItem("token", resp.auth_token);
  }
  return resp.data;
};

const fetchLogout = () => {
  // se borra en el reducer, listener logout
  // localStorage.removeItem('token')
  // localStorage.removeItem('user')
  // localStorage.removeItem('role')
};

const authService = {
  fetchLogin,
  fetchLogout,
};

export default authService;
