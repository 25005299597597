import request from "./utils/request";
import * as URLS from "./utils/urls";
import { setAuthHeader } from "./utils/utils";

const triggerInsertProcess = async (token, adClientID) => {
  const resp = await request(
    URLS.TRIGGER_INSERT_PROCESS(adClientID),
    "GET",
    {},
    setAuthHeader(token)
  );
  return resp.data;
};

const insertProcessService = {
  triggerInsertProcess,
};

export default insertProcessService;
