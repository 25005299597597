import { isRejectedWithValue } from "@reduxjs/toolkit";
import { authLogout } from "../services/auth/authSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryForbidden = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if ("status" in action.payload && action.payload.status === 401) {
      next(authLogout());
    }
  }

  return next(action);
};
