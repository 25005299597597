const auth = (state) => state.auth;
const roles = (state) => auth(state).role;

export const getUser = (state) => auth(state).user;
export const getRut = (state) => auth(state).rut;
export const getName = (state) => getUser(state).name;
export const getLastname = (state) => getUser(state).lastname;

export const getRoles = (state) => roles(state);
export const isSolutecInsertion = (state) => auth(state).solutec_insertion;
export const isBlumosInsertion = (state) => auth(state).blumos_insertion;
