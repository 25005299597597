import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, styled } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import logo from "../../assets/svg/logo.svg";

const DashboardContainer = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

const LoginView = ({ formik, isError, handleBlur, loading, roleError, setRoleError }) => {
  return (
    <DashboardContainer>
      <Box
        sx={{
          mt: { xs: 10, sm: 20 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: { xs: 300, md: 400, lg: 500 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          elevation={4}
        >
          <img style={{ paddingBottom: 20 }} width={200} src={logo} />
          <Typography style={{ color: "#484848" }} component="h1" variant="h5">
            Transformación Digital
          </Typography>
          <Box onSubmit={formik.handleSubmit} component={"form"} noValidate sx={{ mt: 1 }}>
            <TextField
              id="email"
              name="email"
              margin="normal"
              label="Ingrese su rut"
              fullWidth
              autoFocus
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={handleBlur}
              error={(formik.touched.email && Boolean(formik.errors.email)) || isError}
              helperText={(formik.touched.email || isError) && formik.errors.email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={handleBlur}
              onFocus={handleBlur}
              error={(formik.touched.password && Boolean(formik.errors.password)) || isError}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Box sx={{ position: "relative" }}>
              <Button
                disabled={loading.started}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Iniciar Sesión
              </Button>
              {loading.started && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary.main",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-8px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
          {roleError && (
            <Box>
              <Typography style={{ color: "#484848" }} component="h1" variant="h5">
                No cuenta con los permisos necesarios.
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </DashboardContainer>
  );
};

LoginView.propTypes = {};

export default LoginView;
