export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const setupErrorPayload = (error) => {
  console.log("ERROR() => ", error);
  let response = null;
  let message = null;
  let status = null;
  let statusText = null;

  if (("response" in error) && error.response) {
    response = error.response;
    if ("status" in response && response.status) {
      status = response.status;
    }
    if ("statusText" && response.statusText) {
      statusText = response.statusText;
    }
    if (
      "data" in response &&
      response.data &&
      "message" in response.data &&
      response.data.message
    ) {
      message = response.data.message;
    }
  }

  const payload = {
    error: true,
    status: status ? status : 500,
    statusText: statusText ? statusText : "Internal server error",
    message: message ? message : "Something unexpected happened",
  };
  console.log("PAYLOAD() => ", payload);
  return payload;
};
