import React from "react";
import PropTypes from "prop-types";
import NavbarView from "app/components/navbar/NavbarView";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../../services/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { getRut } from "../../../services/auth/selectors";

const Navbar = (props) => {
  const dispatch = useDispatch();
  const rut = useSelector((state) => getRut(state));
  const navigate = useNavigate();

  const getInitials = () => {
    return rut;
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
  };
  const updated = "2022/08/14";
  return (
    <NavbarView rut={rut} initials={getInitials()} updated={updated} handleLogout={handleLogout} />
  );
};

Navbar.propTypes = {};

export default Navbar;
