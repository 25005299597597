import React, { useRef } from "react";
import PropTypes from "prop-types";
import Navbar from "../../components/navbar/Navbar";
import { Container, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const DashboardContainer = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  position: "relative",
  display: "flex",
  flexDirection: "column",
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InsertProcessView = ({ isSolutecInsetable, isBlumosInsertable, handleInsertProcess }) => {
  return (
    <DashboardContainer>
      <Navbar />
      <Container maxWidth={"lg"}>
        <Box sx={{ width: "100%" }}>
          {isBlumosInsertable && (
            <>
              <Box py={2}>
                <Typography variant={"h5"}>Insertar datos de Blumos</Typography>
                <Button onClick={() => handleInsertProcess(1000000)}>Insertar</Button>
              </Box>
            </>
          )}
          {isSolutecInsetable && (
            <>
              <Box py={2}>
                <Typography variant={"h5"}>Insertar datos de Solutec</Typography>
                <Button onClick={() => handleInsertProcess(1000005)}>Insertar</Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </DashboardContainer>
  );
};

InsertProcessView.propTypes = {
  isSolutecInsetable: PropTypes.bool.isRequired,
  isBlumosInsertable: PropTypes.bool.isRequired,
};

export default InsertProcessView;
