import { ERROR_MODEL, LOADING } from "../../app/config/constants";
import { createSlice } from "@reduxjs/toolkit";
import { insertBase } from "./types";
import { authLogout, reset } from "../auth/authSlice";
import { triggerInsertProcess } from "./actions";

const initialState = {
  loading: LOADING.INITIAL,
  error: ERROR_MODEL,
};

export const insertProcessSlice = createSlice({
  name: insertBase,
  initialState,
  reducers: {
    resetInsertProcess: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Reception
      .addCase(reset, (state) => initialState)
      .addCase(authLogout, (state) => initialState)
      .addCase(triggerInsertProcess.pending, (state, action) => {
        state.loading = LOADING.STARTED;
        state.error = ERROR_MODEL;
      })
      .addCase(triggerInsertProcess.fulfilled, (state, action) => {
        console.log("PAYLOAD() => ", action.payload);
        state.loading = LOADING.SUCCESS;
        state.error = ERROR_MODEL;
      })
      .addCase(triggerInsertProcess.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = LOADING.FAILURE;
      });
  },
});

export const { resetInsertProcess } = insertProcessSlice.actions;

export default insertProcessSlice.reducer;
