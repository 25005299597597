export const LOADING = {
  INITIAL: { initial: true, started: false, success: false, failure: false },
  STARTED: { initial: false, started: true, success: false, failure: false },
  SUCCESS: { initial: false, started: false, success: true, failure: false },
  FAILURE: { initial: false, started: false, success: false, failure: true },
};

export const ERROR_MODEL = {
  error: false,
  status: 0,
  statusText: "",
  message: "",
};
