import { setAuthHeader } from "./utils/utils";
import * as URLS from "./utils/urls";
import request from "./utils/request";

const getUser = async (token) => {
  const resp = await request(URLS.GET_USER, "GET", {}, setAuthHeader(token));
  // if (resp.data) {
  //   localStorage.setItem('user', JSON.stringify(resp.data))
  // }
  return resp.data;
};

const userService = {
  getUser: getUser,
};

export default userService;
