import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles, isBlumosInsertion, isSolutecInsertion } from "../../../services/auth/selectors";
import { triggerInsertProcess } from "../../../services/insertProcess/actions";
import InsertProcessView from "./InsertProcessView";

const InsertProcess = (props) => {
  const dispatch = useDispatch();
  const isSolutecInsetable = useSelector((state) => isSolutecInsertion(state));
  const isBlumosInsertable = useSelector((state) => isBlumosInsertion(state));

  const handleInsertProcess = (adClientId) => {
    dispatch(triggerInsertProcess(adClientId));
  };

  return (
    <InsertProcessView
      handleInsertProcess={handleInsertProcess}
      isSolutecInsetable={isSolutecInsetable}
      isBlumosInsertable={isBlumosInsertable}
    />
  );
};

InsertProcess.propTypes = {};

export default InsertProcess;
