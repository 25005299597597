import { setAuthHeader } from "./utils/utils";
import * as URLS from "./utils/urls";
import request from "./utils/request";

const getRoleByUserId = async (token, user_id) => {
  const resp = await request(URLS.GET_ROLE_BY_USER_ID(user_id), "GET", {}, setAuthHeader(token));
  // if (resp.data) {
  //   localStorage.setItem('role', JSON.stringify(resp.data))
  // }
  return resp.data;
};

const roleService = {
  getRoleByUserId,
};

export default roleService;
