import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "api/authAPI";
import userService from "api/userAPI";
import roleService from "api/roleAPI";

const initialState = {
  jwt: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoadingUser: false,
  isSuccessUser: false,
  isErrorUser: false,
  messageUser: "",
  isLoadingRole: false,
  isSuccessRole: false,
  isErrorRole: false,
  messageRole: "",
  roles_blumos: [],
  roles_solutec: [],
  blumos_insertion: false,
  solutec_insertion: false,
  rut: "",
};

// Auth
export const login = createAsyncThunk("auth/fetchLogin", async (credentials, thunkAPI) => {
  try {
    return await authService.fetchLogin(credentials);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("auth/fetchLogout", async () => {
  await authService.fetchLogout();
});

// User

export const getUser = createAsyncThunk("user/getUserByEmail", async (email, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.jwt;
    return await userService.getUser(token, email);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Role

export const getRole = createAsyncThunk("rol/getRoleByUserId", async (user_id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.jwt;
    return await roleService.getRoleByUserId(token, user_id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
    authLogout: (state) => initialState,
    saveRut: (state, action) => {
      state.rut = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Auth
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jwt = action.payload.auth_token;
        state.roles_blumos = action.payload.roles_blumos;
        state.roles_solutec = action.payload.roles_solutec;
        if (action.payload.roles_blumos.includes("insertion_trigger")) {
          state.blumos_insertion = true;
          state.isSuccessUser = true;
        }
        if (action.payload.roles_solutec.includes("insertion_trigger")) {
          state.solutec_insertion = true;
          state.isSuccessUser = true;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.jwt = null;
      })
      // User
      .addCase(getUser.pending, (state) => {
        state.isLoadingUser = true;
        state.isLoadingRole = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoadingUser = false;
        state.isSuccessUser = true;
        state.user = action.payload.user;
        state.isLoadingRole = false;
        state.isSuccessRole = true;
        state.role = action.payload.roles.map((role) => role.name);
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoadingUser = false;
        state.isErrorUser = true;
        state.messageUser = action.payload;
        state.user = null;
        state.isLoadingRole = false;
        state.isErrorRole = true;
        state.messageRole = action.payload;
        state.role = null;
      });
  },
});

export const { reset, authLogout, saveRut } = authSlice.actions;
export default authSlice.reducer;
