import { API_URL } from "../../app/config/settings";

// URL WITHOUT PARAMETERS

export const AUTH_LOGIN = `${API_URL}/auth`;
export const USERS = `${API_URL}/users`;
export const ROLES = `${API_URL}/roles`;
export const ACCESS = `${API_URL}/acceso`;
export const ACCESS_AUTHORIZATION = `${API_URL}/access_authorization`;
export const ACCESS_AUTHORIZATION_USER = `${API_URL}/access_authorization/user`;

// URL WITH PARAMETERS

export const GET_USER = USERS;
export const GET_ROLE_BY_USER_ID = (user_id) => {
  return `${ROLES}?user_id=${user_id}`;
};

export const CANCEL_AUTHORIZATION = (authorizationId) =>
  `${ACCESS_AUTHORIZATION}/cancel/${authorizationId}`;

export const INSERT_PROCESS = `${API_URL}/insert_process`;
export const TRIGGER_INSERT_PROCESS = (adClientId) => `${INSERT_PROCESS}/${adClientId}`;
